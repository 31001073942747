export const projectRetail_themes = {
  blue: {
    background: "#6a80fb",
    text1: "white",
  },
  black: {
    background: "black",
    text1: "white",
  },
  white: {
    background: "white",
    text1: "black",
  },
}
